<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('transaction.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-col
      cols="12"
      class="text-right add--button"
      v-if="permissions.includes('EDIT') && !isEditing"
    >
      <v-btn color="success" class="bottom--button mr-0" @click="startEdit">
        {{ $t('transaction.btn.edit') }}
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      class="text-right add--button"
      v-if="permissions.includes('EDIT') && isEditing"
    >
      <v-btn color="success" class="bottom--button mr-0" @click="submit">
        {{ $t('transaction.btn.save') }}
      </v-btn>
    </v-col>
    <form @submit.prevent="submit">
      <div class="form--group row">
        <label class="col-12 col-lg-4">Harga Final</label>
        <div class="col-12 col-lg-8">
          <cleave
            v-model="finalPrice"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="finalPrice"
            type="text"
            placeholder="Harga Final"
            :readonly="!isEditing"
          />
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('selectedSettlementMethod') }"
      >
        <label class="col-12 col-lg-4">Metode Pelunasan</label>
        <div class="col-12 col-lg-8">
          <multiselect
            name="selectedSettlementMethod"
            id="selectedSettlementMethodField"
            v-model="selectedSettlementMethod"
            :options="settlementMethods"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
            :disabled="!isEditing"
          />
          <span class="val-error">{{ validation.firstError('selectedSettlementMethod') }}</span>
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Booking Fee</label>
        <div class="col-12 col-lg-8">
          {{ bookingFeeType }}
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Jumlah Booking Fee</label>
        <div class="col-12 col-lg-8">
          {{ $n(bookingFeePrice, 'currency', 'id-ID') }}
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Jumlah DP</label>
        <div class="col-12 col-lg-8">
          <cleave
            v-model="dpPrice"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="dpPrice"
            type="text"
            placeholder="Jumlah DP"
            :readonly="!isEditing"
          />
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Jumlah Cicilan DP</label>
        <div class="col-12 col-lg-8">
          <v-text-field
            type="number"
            outlined
            hide-details
            v-model="dpInstallmentAmount"
            :readonly="!isEditing"
          />
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Sisa Pembayaran</label>
        <div class="col-12 col-lg-8">
          <cleave
            :value="paymentLeft"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="paymentLeft"
            type="text"
            placeholder="Sisa Pembayaran"
            readonly
          />
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Jumlah Cicilan Cash</label>
        <div class="col-12 col-lg-8">
          <v-text-field
            type="number"
            outlined
            hide-details
            v-model="cashInstallmentAmount"
            :readonly="!isEditing"
          />
        </div>
      </div>
    </form>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: { Multiselect },
  data() {
    return {
      format,
      isEditing: false,
      // paymentLeft: 0,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      settlementMethods: (state) => state.transaction.form.settlementMethods,
      bookingFeePrice: (state) => state.transaction.form.bookingFeePrice,
      bookingFeeType: (state) => state.transaction.form.bookingFeeType,
    }),
    paymentLeft() {
      if (this.finalPrice) {
        if (this.dpPrice) {
          return parseInt(this.finalPrice) - parseInt(this.dpPrice);
        }
        return this.finalPrice;
      }
      return 0;
    },
    finalPrice: {
      get() {
        return this.$store.state.transaction.form.unitPrice;
      },
      set(value) {
        this.$store.commit('transaction/form/SET_UNIT_PRICE', value);
      },
    },
    selectedSettlementMethod: {
      get() {
        return this.$store.state.transaction.form.selectedSettlementMethod;
      },
      set(value) {
        this.$store.commit('transaction/form/SET_SELECTED_SETTLEMENT_METHOD', value);
      },
    },
    dpPrice: {
      get() {
        return this.$store.state.transaction.form.dpPrice;
      },
      set(value) {
        this.$store.commit('transaction/form/SET_DP_PRICE', value);
      },
    },
    dpInstallmentAmount: {
      get() {
        return this.$store.state.transaction.form.dpInstallmentAmount;
      },
      set(value) {
        this.$store.commit('transaction/form/SET_DP_INSTALLMENT_AMOUNT', value);
      },
    },
    cashInstallmentAmount: {
      get() {
        return this.$store.state.transaction.form.cashInstallmentAmount;
      },
      set(value) {
        this.$store.commit('transaction/form/SET_CASH_INSTALLMENT_AMOUNT', value);
      },
    },
  },
  validators: {},
  methods: {
    async deleteAction(id) {
      try {
        await this.$store.dispatch('transaction/delete', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('transaction.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    startEdit() {
      this.isEditing = true;
    },
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('transaction/form/updateTransaction');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('transaction.update.successMsg'),
          'success',
        );
        this.isEditing = false;
        await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
